<template>
  <div class="black-week-banner">
    <NuxtLink :to="blackWeekPageUrl" external>
      <NuxtImg class="h-full hidden lg:block" :src="bannerImageUrls.desktop" />
      <NuxtImg class="h-full block lg:hidden" :src="bannerImageUrls.mobile" />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const blackWeekPageUrl = 'https://www.expert.de/blackweeks';
const bannerImageUrls = {
  desktop: '/53/e8/59/f1134edf3f7c31dcc5975e4dbd19fd84d9/Banner_ueber_Buehne.png',
  mobile: '/07/1f/18/056555dc8e9b9640c445674072dd705ed5/BlackWeeks_2023_Kategoriebanner_945x200.jpg',
};
</script>

<style scoped>
.black-week-banner {
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  height: 100px;
}
</style>